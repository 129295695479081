var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-skeleton-loader',{attrs:{"loading":_vm.loadingTable,"transition":'fade-transition',"height":"500","type":"table"}},[_c('v-data-table',{attrs:{"headers":_vm.getHeaders(_vm.typesNotHeader),"items":_vm.dataTable,"items-per-page":_vm.dataTable.length,"hide-default-footer":"","dense":""},scopedSlots:_vm._u([{key:"item.entity_cod",fn:function(ref){
var item = ref.item;
return [[_c('span',{staticClass:"text-truncate text-name"},[_vm._v(" "+_vm._s(item.entity_cod)+" - "+_vm._s(item.entity_name)+" ")])]]}},{key:"item.deve_ant",fn:function(ref){
var item = ref.item;
return [[_vm._v(" "+_vm._s(_vm._f("currencyNoSymbol")(item.deve_ant))+" ")]]}},{key:"item.proposta",fn:function(ref){
var item = ref.item;
return [(_vm.typeGecom === 4)?_c('v-btn',{staticStyle:{"min-width":"100px"},attrs:{"elevation":"1","color":"#FFF","plain":"","raised":"","small":"","tile":"","height":20},on:{"click":function($event){return _vm.openProposta(item)}}},[_vm._v(" "+_vm._s(_vm._f("currencyNoSymbol")(item.proposta))+" ")]):[_vm._v(" "+_vm._s(_vm._f("currencyNoSymbol")(item.proposta))+" ")]]}},{key:"item.comissao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyNoSymbol")(item.comissao))+" ")]}},{key:"item.liquido",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyNoSymbol")(item.liquido))+" ")]}},{key:"item.lp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyNoSymbol")(item.lp))+" ")]}},{key:"item.premio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyNoSymbol")(item.premio))+" ")]}},{key:"item.premios_altos",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyNoSymbol")(item.premios_altos))+" ")]}},{key:"item.caixa",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyNoSymbol")(item.caixa))+" ")]}},{key:"item.pagto",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyNoSymbol")(item.pagto))+" ")]}},{key:"item.deve_atual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyNoSymbol")(item.deve_atual))+" ")]}},{key:"item.dt_ultimo_contato",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("datas")(item.dt_ultimo_contato,'DD/MM/YYYY'))+" ")]}},{key:"item.limite_venda",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currencyNoSymbol")(item.limite_venda))+" ")]}}])},[_c('template',{slot:"body.prepend"},[_c('tr',[(_vm.typeGecom >= 2 && _vm.typeGecom < 4)?_c('th',{attrs:{"colspan":_vm.getHeaders(_vm.typesNotHeader).length}},[_vm._v("Supervisor: "+_vm._s(_vm.supervisor.entity_cod ? ((_vm.supervisor.entity_cod) + " - " + (_vm.supervisor.entity_name)) : ''))]):_vm._e()]),_c('tr',[(_vm.typeGecom >= 3 && _vm.typeGecom < 4)?_c('th',{attrs:{"colspan":_vm.getHeaders(_vm.typesNotHeader).length}},[_vm._v("Promotor: "+_vm._s(_vm.promotor.entity_cod ? ((_vm.promotor.entity_cod) + " - " + (_vm.promotor.entity_name)) : ''))]):_vm._e()])]),_c('template',{slot:"body.append"},[_c('tr',[_c('th',[_vm._v("Total")]),_c('th',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currencyNoSymbol")(_vm.totaisGerais ? _vm.totaisGerais.deve_ant : '0,00')))]),_c('th',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currencyNoSymbol")(_vm.totaisGerais ? _vm.totaisGerais.proposta : '0,00')))]),_c('th',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currencyNoSymbol")(_vm.totaisGerais ? _vm.totaisGerais.comissao : '0,00')))]),_c('th',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currencyNoSymbol")(_vm.totaisGerais ? _vm.totaisGerais.premio : '0,00')))]),_c('th',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currencyNoSymbol")(_vm.totaisGerais ? _vm.totaisGerais.pagto : '0,00')))]),_c('th',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currencyNoSymbol")(_vm.totaisGerais ? _vm.totaisGerais.caixa : '0,00')))]),_c('th',{staticClass:"text-end"},[_vm._v(_vm._s(_vm._f("currencyNoSymbol")(_vm.totaisGerais ? _vm.totaisGerais.deve_atual : '0,00')))]),_c('th')]),_c('tr',[_c('th',{attrs:{"colspan":"13"}},[_vm._v(" Agências: "),_c('span',{staticClass:"font-weight-regular"},[_vm._v(" Ativos:")]),_vm._v(" "+_vm._s(_vm.totaisGerais ? _vm.totaisGerais.ag_ativas : 0)+" ")])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }